<template>
  <div v-if="obj" class="integration-sendgrid">
    <div class="row">
      <div class="col-md-6">
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  props: {
    obj: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
    }
  },

  computed: {
  },

  mounted() {
  },

  methods: {
  },
}
</script>
      
<style lang="scss" scoped></style>